import { AllHTMLAttributes, ReactNode } from 'react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  position,
  border,
  space,
  layout,
  background,
  color,
  grid,
  flex,
  flexbox,
  shadow,
  typography,
  PositionProps,
  LayoutProps,
  SpaceProps,
  ColorProps,
  BorderProps,
  ShadowProps,
  BackgroundProps,
  TypographyProps,
  GridProps as StyledSystemGridProps,
  FlexProps,
  FlexboxProps,
} from 'styled-system';
import { SaveCombine } from '../utils/helperTypes';

const Grid = styled('div', { shouldForwardProp })(
  () => ({
    display: 'grid',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  }),
  grid,
  position,
  layout,
  space,
  color,
  background,
  border,
  flex,
  flexbox,
  shadow,
  typography
);

export type RawGridProps = PositionProps &
  LayoutProps &
  StyledSystemGridProps &
  FlexProps &
  FlexboxProps &
  SpaceProps &
  ColorProps &
  BorderProps &
  ShadowProps &
  BackgroundProps &
  TypographyProps & {
    as?: As;
    children?: ReactNode;
  };

/* The SaveCombine was introduced because there were attribute overlappings which caused problems. For instance the `width` attribute could not be set to a responsive value with arrays. Typescript throws an error ts(2322) here. So the easiest way to solve this was to exclude those overlapping attributes from the AllHTMLAttributes. */
export type GridProps = SaveCombine<
  AllHTMLAttributes<HTMLElement>,
  RawGridProps
>;

type As<P = any> = React.ElementType<P>;

Grid.displayName = 'Grid';
export { Grid };
