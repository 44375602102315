import { AllHTMLAttributes, ReactNode } from 'react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  position,
  border,
  space,
  layout,
  color,
  background,
  flex,
  flexbox,
  shadow,
  typography,
  PositionProps,
  LayoutProps,
  SpaceProps,
  ColorProps,
  BorderProps,
  ShadowProps,
  BackgroundProps,
  TypographyProps,
  FlexProps,
  FlexboxProps,
} from 'styled-system';
import { SaveCombine } from '../utils/helperTypes';

const Flex = styled('div', { shouldForwardProp })<Props>(
  () => ({
    display: 'flex',
    minWidth: 0,
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  }),
  position,
  layout,
  flex,
  flexbox,
  space,
  color,
  background,
  border,
  shadow,
  typography
);

type RawProps = PositionProps &
  LayoutProps &
  FlexboxProps &
  FlexProps &
  SpaceProps &
  ColorProps &
  BorderProps &
  ShadowProps &
  BackgroundProps &
  TypographyProps & {
    as?: As;
    children?: ReactNode;
  };

/* The SaveCombine was introduced because there were attribute overlappings which caused problems. For instance the `width` attribute could not be set to a responsive value with arrays. Typescript throws an error ts(2322) here. So the easiest way to solve this was to exclude those overlapping attributes from the AllHTMLAttributes. */
export type Props = SaveCombine<AllHTMLAttributes<HTMLElement>, RawProps>;

type As<P = any> = React.ElementType<P>;

Flex.displayName = 'Flex';
export { Flex };
